declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[];
  }
}

export const getDataLayer = () => {
  if (typeof window === undefined) {
    throw new Error("[DataLayer] Access server side");
  }

  window.dataLayer = window.dataLayer || [];

  return window.dataLayer;
};

export function generateUniqueID() {
  const characters =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let id = "";

  for (let i = 0; i < 25; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}
