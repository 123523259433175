import { createTheme, responsiveFontSizes } from "@mui/material";

import { fontFamily } from "./fonts";

// theme creator
// https://bareynol.github.io/mui-theme-creator/

// configuration variables
// https://mui.com/material-ui/customization/theming/#theme-configuration-variables

// theme components
// https://mui.com/material-ui/customization/theme-components/

// plein de paragraph du site ont la couleur de text #484848 /

let theme = createTheme({
  palette: {
    background: {
      rose: "#fef6f8",
      gris: "#f5f6f7",
      vert: "#e9f1f0",
      sable: "#f4f0eb",
      landingOwner: "#CBDDF1",
      landingTenant: "#FAF7F4",
    },
    primary: {
      lighter: "#ffd7e0",
      light: "#f26c88",
      main: "#0466C9",
      dark: "#222D5A",
    },
    secondary: {
      lighter: "#9197ac",
      light: "#4e577b",
      main: "#222d5a",
      dark: "#171f3e",
    },
    gray: {
      main: "#7E7E7E",
      light: "#585960",
      lighter: "#EAEAF0",
      dark: "#A8A8A8",
    },
    gold: {
      light: "#EEEADE",
      main: "#B8A675",
      opacity50: "rgba(184, 166, 117, 0.50)",
      opacity20: "rgba(184, 166, 117, 0.2)",
      opacity15: "rgba(184, 166, 117, 0.15)",
      dark: "#AC9B6C",
    },
    // green: {
    //   lighter: "#93b9b2",
    //   light: "#e9f1f0",
    //   main: "#147a6e",
    //   dark: "#22685A",
    //   contrastText: "#fff",
    // },
    landingOwner: {
      lighter: "#E8EFF7",
      light: "#CBDDF1",
      main: "rgba(64, 144, 242, 0.32)",
      dark: "#4090F2",
    },
    landingTenant: {
      lighter: "#FAF7F4",
      light: "#FAF7F4",
      main: "#F0CFB2",
      dark: "#F0CFB2",
    },
    // nouvelle charte graphique
    green: {
      lighter: "#D4F2D9",
      light: "#0FCC8D",
      main: "#185E71",
      dark: "#114755",
      contrastText: "#fff",
    },
    blue: {
      light: "#E8EFF7",
      main: "#4090F2",
      dark: "#004485",
      contrastText: "#fff",
    },
    pink: {
      light: "#FFE0F7",
      main: "#FF9DE6",
      dark: "#97006E",
      contrastText: "#fff",
    },
    orange: {
      light: "#FCE3DE",
      main: "#FF7447",
      dark: "#B82F37",
      contrastText: "#fff",
    },
    yellow: {
      light: "#F6F1D1",
      main: "#FBBC04",
      dark: "#DAA30B",
      contrastText: "#fff",
    },
    //brand
    navy: {
      light: "#CBDDF1",
      main: "#001C64",
      dark: "#4090F2",
      lighter: "#E9EFF7",
    },
    toro: {
      main: "#FCE3DE",
    },
    coconut: {
      main: "#FAF7F4",
    },
    coral: {
      light: "#FFE0E9",
      main: "#0466C9",
      contrastText: "#fff",
    },
    mint: {
      main: "#D4F2D9",
    },
    grass: {
      main: "#0FCC8D",
    },
    forest: {
      main: "#114755",
    },
    snow: {
      main: "#E8EFF7",
    },
    sea: {
      main: "#4090F2",
    },
    gum: {
      main: "#FFE0F7",
    },
    guava: {
      main: "#FF9DE6",
    },
    pomegranate: {
      main: "#97006E",
    },
    papaya: {
      main: "#FF7447",
    },
    cinamon: {
      main: "#B82F37",
    },
    sand: {
      main: "#F6F1DA",
    },
    sun: {
      main: "#FFD369",
    },
    ananas: {
      main: "#DAA30B",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: `${fontFamily}`,
    button: { textTransform: "none" },
    h1: {
      fontSize: "3.5em",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5em",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.5em",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.075em",
      fontWeight: 500,
    },
    h5: {
      fontSize: "O.75em",
      fontWeight: 500,
    },
    overline: {
      fontSize: "1em",
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body2: {
      fontSize: "0.85em",
      fontWeight: 100,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        sizeSmall: `font-weight: 500;font-size: 0.95em;`,
        sizeMedium: `font-weight: 400; font-size: 0.95em;`,
        sizeLarge: {
          fontWeight: 500,
          fontSize: "1em",
          padding: "8px 20px",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

theme = responsiveFontSizes(theme);
// BLEU NUIT #222d5a rgb 24 45 90 CMJN 100 90 0 50 RAL 5022 PANTONE 655CP
// ROSE #ef486b rgb 240 70 100 CMJN 0 88 48 0 PANTONE 191 C
// SABLE #DBCCA3 RGB 220 204 163 CMJN 16 17 40 1
// VERT #147A6E RGB 220 204 163 CMJN 16 17 40 1
// TAUPE #9C8F8C RGB 155 142 141 CMJN 38 37 35 15

// @todo scrap smart renting for theme
// #ee4564 // du rose dans le logo mais pas trop utilisé ailleurs
// #ee486B // le rose des boutons et des liens a
// #0a1a5c // le bleu du logo
// #f5f6f7 // couleur du texte du jumbotron haut de la page
// #181c44 // couleur du texte temoignage
// #232e59 // texte des cartes
// #232e59 // couleur du hamburger
export default theme;

declare module "@mui/material/styles" {
  interface TypeBackground {
    vert: string;
    sable: string;
    rose: string;
    gris: string;
    landingOwner: string;
    landingTenant: string;
  }
  interface PaletteOptions {
    landingOwner: PaletteOptions["primary"];
    landingTenant: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    gray: PaletteOptions["secondary"];
    blue: PaletteOptions["primary"];
    pink: PaletteOptions["primary"];
    orange: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
  }
  interface Palette {
    landingOwner: Palette["primary"];
    landingTenant: Palette["primary"];
    green: Palette["primary"];
    gray: Palette["secondary"];
    blue: Palette["primary"];
    pink: Palette["primary"];
    orange: Palette["primary"];
    yellow: Palette["primary"];
  }

  interface PaletteColor {
    lighter?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    landingOwner: true;
    landingTenant: true;
    green: true;
    gray: true;
    blue: true;
    pink: true;
    orange: true;
    yellow: true;
  }
}
